import React from 'react';

import Meta from '../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';

import SubPageNav from '../components/subPageNav';

const Philosophy = () =>{
    return(
        <>

            <Meta
                title="経営理念"
                description="アクロスソリューションズの経営理念についてご案内します。"
            />

            <Contents>

                <div className="c-sub-header">
                    <SubPageNav static="philosophy" />

                    <em className="c-sub-header--eng u-josef is-psp">
                    PHILOSOPHY
                    </em>

                    <h1 className="c-sub-header--title">
                    経営理念
                    </h1>
                </div>


                <div className="p-psp">

                    <div className="p-psp-box">

                        <div className="u-margin-bottom__2">
                            <h2 className="p-psp-box-title">
                                社是
                            </h2>
                            <p className="p-psp-box-sub">
                                COMPANY CREED
                            </p>
                        </div>

                        <div className="u-margin-bottom__4 u-align-center">
                            <StaticImage
                            src="../images/company_creed_texture.png"
                            alt="株式会社アクロスソリューションズ 社是"
                            placeholder="blurred"
                            quality="100"
                            />

                            <p className="p-psp-box-text is-mini u-margin-top__1">
                                アクロスソリューションズ社是<span className="is-deco">「至誠と創造」</span>
                            </p>
                        </div>

                        <p className="p-psp-box-text">
                        至誠は、誠実や真面目という意味ですが、仕事に対して、お客様に対して、誠実に取り組んでいこうという考えをベースにしています。創造は、新しいものを生み育てる、チャレンジすることです。
                        </p>

                    </div>

                    <div className="p-psp-box">

                        <div className="u-margin-bottom__2">
                            <h2 className="p-psp-box-title">
                                経営理念
                            </h2>
                            <p className="p-psp-box-sub u-josef">
                                COMPANY IDENTITY
                            </p>
                        </div>

                        <div className="p-psp-box-list">

                            <div className="is-item">
                                <h3 className="is-item-title">
                                社会への貢献
                                </h3>
                                <p className="is-item-text">
                                私たちは、コンピュータシステムによる情報技術の推進を通して、豊かな社会の発展に貢献します。
                                </p>
                            </div>

                            <div className="is-item">
                                <h3 className="is-item-title">
                                顧客サービスの向上
                                </h3>
                                <p className="is-item-text">
                                私たちは、常にお客様のニーズにすばやく対応し、ベストソリューションの提供とサービス向上を通して、お客様と確かな信頼関係を築きます。
                                </p>
                            </div>

                            <div className="is-item">
                                <h3 className="is-item-title">
                                価値の共有
                                </h3>
                                <p className="is-item-text">
                                私たちは、健全な企業活動を通して、株主と価値を分かち合いながら社員の能力を十分発揮できる環境と幸福で豊かなライフステージの創出に努めます。
                                </p>
                            </div>

                        </div>

                    </div>

                </div>



            </Contents>

        </>
    )
}

export default Philosophy;


